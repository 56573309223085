#contact-us-page {
  .form-type-textfield,
  .form-type-textarea {
    label {
      font-family: $helvetica-bold-font-family;
    }
  }
  p.desc {
    color: $color-black;
    font-family: $helvetica-regular-font-family;
  }
  h2 {
    font-family: $helvetica-bold-font-family;
    color: $color-black;
  }
  a {
    color: $color-black;
    font-family: $helvetica-regular-font-family;
    border-bottom: 1px solid $color-black;
  }
  .form-submit {
    background-color: $color-black;
  }
}
